import React from 'react';

export const Structure = () => {
  return (
    <div>
      <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Recusandae obcaecati aspernatur ex
        nihil et adipisci nemo, illo qui. Illum modi nam nemo laboriosam excepturi? Sit labore nam 
        eveniet molestias distinctio vero, totam nesciunt unde perspiciatis itaque! Repudiandae
        beatae sint, a in harum maxime accusamus aut ab sed eveniet odio, facilis autem magni quia
        accusantium hic dolore, adipisci nulla praesentium mollitia ipsam corrupti quidem minus
        ullam? Tempore neque ipsam cumque atque iure, veritatis officiis, dignissimos hic, quae
        fugit placeat asperiores libero reiciendis consequuntur. Aliquid aut, obcaecati ad nulla
        possimus beatae repellendus esse ex hic cum aperiam debitis minima repudiandae architecto
        eum.
        </p>
        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Recusandae obcaecati aspernatur ex
        nihil et adipisci nemo, illo qui. Illum modi nam nemo laboriosam excepturi? Sit labore nam 
        eveniet molestias distinctio vero, totam nesciunt unde perspiciatis itaque! Repudiandae
        beatae sint, a in harum maxime accusamus aut ab sed eveniet odio, facilis autem magni quia
        accusantium hic dolore, adipisci nulla praesentium mollitia ipsam corrupti quidem minus
        ullam? Tempore neque ipsam cumque atque iure, veritatis officiis, dignissimos hic, quae
        fugit placeat asperiores libero reiciendis consequuntur. Aliquid aut, obcaecati ad nulla
        possimus beatae repellendus esse ex hic cum aperiam debitis minima repudiandae architecto
        eum.
        </p>
        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Recusandae obcaecati aspernatur ex
        nihil et adipisci nemo, illo qui. Illum modi nam nemo laboriosam excepturi? Sit labore nam 
        eveniet molestias distinctio vero, totam nesciunt unde perspiciatis itaque! Repudiandae
        beatae sint, a in harum maxime accusamus aut ab sed eveniet odio, facilis autem magni quia
        accusantium hic dolore, adipisci nulla praesentium mollitia ipsam corrupti quidem minus
        ullam? Tempore neque ipsam cumque atque iure, veritatis officiis, dignissimos hic, quae
        fugit placeat asperiores libero reiciendis consequuntur. Aliquid aut, obcaecati ad nulla
        possimus beatae repellendus esse ex hic cum aperiam debitis minima repudiandae architecto
        eum.
        </p>
        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Recusandae obcaecati aspernatur ex
        nihil et adipisci nemo, illo qui. Illum modi nam nemo laboriosam excepturi? Sit labore nam 
        eveniet molestias distinctio vero, totam nesciunt unde perspiciatis itaque! Repudiandae
        beatae sint, a in harum maxime accusamus aut ab sed eveniet odio, facilis autem magni quia
        accusantium hic dolore, adipisci nulla praesentium mollitia ipsam corrupti quidem minus
        ullam? Tempore neque ipsam cumque atque iure, veritatis officiis, dignissimos hic, quae
        fugit placeat asperiores libero reiciendis consequuntur. Aliquid aut, obcaecati ad nulla
        possimus beatae repellendus esse ex hic cum aperiam debitis minima repudiandae architecto
        eum.
        </p>
        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Recusandae obcaecati aspernatur ex
        nihil et adipisci nemo, illo qui. Illum modi nam nemo laboriosam excepturi? Sit labore nam 
        eveniet molestias distinctio vero, totam nesciunt unde perspiciatis itaque! Repudiandae
        beatae sint, a in harum maxime accusamus aut ab sed eveniet odio, facilis autem magni quia
        accusantium hic dolore, adipisci nulla praesentium mollitia ipsam corrupti quidem minus
        ullam? Tempore neque ipsam cumque atque iure, veritatis officiis, dignissimos hic, quae
        fugit placeat asperiores libero reiciendis consequuntur. Aliquid aut, obcaecati ad nulla
        possimus beatae repellendus esse ex hic cum aperiam debitis minima repudiandae architecto
        eum.
        </p>

    </div>
  )
};
